import React from 'react'
import { graphql } from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import BlogPost from '../components/blog-post'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import { toPlainText } from '../lib/helpers'

export const query = graphql`
  query BlogPostTemplateQuery($id: String!) {
    file(relativePath: { eq: "ajmal.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 150, height: 150, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        fluid(maxWidth: 150, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    post: sanityPost(id: { eq: $id }) {
      id
      publishedAt
      categories {
        _id
        title
        slug {
          current
        }
      }
      mainImage {
        ...ImageWithPreview
        caption
        alt
      }
      title
      slug {
        current
      }
      _rawExcerpt(resolveReferences: { maxDepth: 5 })
      _rawBody(resolveReferences: { maxDepth: 5 })
      _updatedAt
      authors {
        _key
        author {
          image {
            ...ImageWithPreview
            asset {
              _id
              id
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
          name
        }
      }
    }
  }
`

const BlogPostTemplate = (props) => {
  const { data, errors, pageContext } = props
  const post = data && data.post 
  const file = data && data.file

  return (
    <Layout>
      {errors && <SEO title="GraphQL Error" />}
      {post && (
        <SEO
          title={post.title || 'Untitled'}
          description={toPlainText(post._rawExcerpt)}
          image={post.mainImage}
        />
      )}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}

      {post && <BlogPost {...post} {...file} {...pageContext} />}
    </Layout>
  )
}

export default BlogPostTemplate